import { translate } from "@ollie-sports/i18n";
import _ from "lodash";
import { View } from "react-native-web";
import CoolerTable from "./CoolerTable";
import { CoolSelectInput } from "./Inputs/CoolSelectInput";
import { CoolTextInput } from "./Inputs/CoolTextInput";
import { Org, OrgSeason, Team, TeamSettings } from "@ollie-sports/models";
import { useOrgTeamTags } from "../hooks/useOrgTeamTags";
import { useOrgRegistrationPackages } from "../hooks/useOrgRegistrationPackages";
import { COLORS } from "@ollie-sports/core";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import { useLocation } from "react-router";
import { getBifrost } from "../services/bifrost.service";
import { prettyGender } from "../utils/genderUtils";

export function TeamsTable(p: {
  showExtraDetails?: boolean;
  teamsWithSettingsByTeamId: Record<string, { team: Team; settings?: TeamSettings }>;
  org?: Org;
}) {
  const [searchInput, setSearchInput] = useState("");
  const [selectedBirthYear, setSelectedBirthYear] = useState("");
  const [selectedOrgTeamTagId, setSelectedOrgTeamTagId] = useState("");
  const [selectedOrgRegistrationPackageId, setSelectedOrgRegistrationPackageId] = useState("");

  const teamsAndSettingsArr = Object.values(p.teamsWithSettingsByTeamId);

  const uniqeTagIds = _(teamsAndSettingsArr)
    .map(a => Object.keys(a.team.assignedOrgTagIds || {}))
    .flatten()
    .uniq()
    .value();

  const uniqueSeasonIds = _(teamsAndSettingsArr)
    .map(a => Object.keys(a.settings?.registrationPackageIdBySeasonId || {}))
    .flatten()
    .uniq()
    .value();

  const uniqueRegistrationPackageIds = _(teamsAndSettingsArr)
    .map(a =>
      _(Object.values(a.settings?.registrationPackageIdBySeasonId || {}))
        .compact()
        .value()
    )
    .flatten()
    .uniq()
    .value();

  const { data: orgSeasons } = getBifrost().orgSeason__client__getMultiOrgSeasonsSubscription.useClientSubscription({
    orgSeasonIds: uniqueSeasonIds
  });

  const { data: orgTeamTags } = getBifrost().orgTeamTag__client__getMultiTeamTagsSubscription.useClientSubscription({
    orgTeamTagIds: uniqeTagIds
  });

  const { data: orgRegistrationPackages } =
    getBifrost().orgRegistrationPackage__client__getMultiOrgRegistrationPackagesSubscription.useClientSubscription({
      packageIds: uniqueRegistrationPackageIds
    });

  const { pathname } = useLocation();

  const birthYearOptions = _.uniq(_.compact(teamsAndSettingsArr.map(a => a.team.birthYear))).map(birthYear => {
    return {
      value: birthYear,
      label: birthYear
    };
  });

  const orgSeasonsMap = orgSeasons?.reduce((acc, a) => {
    acc[a.id] = a;
    return acc;
  }, {} as Record<string, OrgSeason>);

  //Only show season in parentheses if there's at least two separate unarchived seasons represented by registration packages.
  const tempSeasonMap: Record<string, true> = {};
  const shouldShowVerboseRegistrationPackages = teamsAndSettingsArr.some(a => {
    Object.keys(a.settings?.registrationPackageIdBySeasonId || {})
      .filter(b => orgSeasonsMap?.[b])
      .forEach(seasonId => {
        tempSeasonMap[seasonId] = true;
      });

    return Object.keys(tempSeasonMap).length > 1;
  });

  return (
    <div className="pb-8">
      <CoolerTable
        style={{ marginTop: 30 }}
        items={teamsAndSettingsArr}
        getItemKey={a => a.team.id}
        paginationOptions={{
          defaultPageSize: 25,
          pageSizeOptions: [25, 50, 100]
        }}
        defaultSortSettings={{
          label: translate({ defaultMessage: "Team Name" }),
          dir: "asc"
        }}
        columnDefs={[
          {
            label: translate({ defaultMessage: "Team Name" }),
            getValue(item) {
              return item.team.name;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.team.name, dir);
            }
          },
          {
            label: translate.common.BirthYear,
            getValue(item) {
              return item.team.birthYear;
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.team.birthYear, dir);
            }
          },
          {
            isVisible: !!p.showExtraDetails,
            label: translate({ defaultMessage: "M/F", description: "Male or female short" }),
            getValue(item) {
              return prettyGender(item.team.gender);
            },
            sortItems(items, dir) {
              return _.orderBy(items, a => a.team.gender, dir);
            }
          },
          {
            label: translate({ defaultMessage: "Tags" }),
            getValue(item) {
              const tags = Object.keys(item.team.assignedOrgTagIds || {})
                .map(a => orgTeamTags?.find(b => b.id === a)!)
                .filter(Boolean);
              return (
                <View style={{ flexDirection: "row", flexWrap: "wrap", maxWidth: 200 }}>
                  {tags.map(a => (
                    <Tag key={a.id} text={a.tag} />
                  ))}
                </View>
              );
            }
          },
          {
            getValue: item => <div>{Object.keys(item.team.derived.activePlayerIds).length}</div>,
            label: translate({ defaultMessage: "# Players" }),
            sortItems(items, dir) {
              return _.orderBy(items, a => Object.keys(a.team.derived.activePlayerIds).length, dir);
            }
          },
          {
            getValue: item => <div>{Object.keys(item.team.accounts).length}</div>,
            label: translate({ defaultMessage: "# Accounts" }),
            sortItems(items, dir) {
              return _.orderBy(items, a => Object.keys(a.team.accounts).length, dir);
            }
          },
          {
            isVisible: !!p.showExtraDetails,
            label: translate.common.RegistrationPackages,
            getValue(item) {
              return (
                <View style={{ flexDirection: "row", flexWrap: "wrap", maxWidth: 200 }}>
                  {_(Object.keys(item.settings?.registrationPackageIdBySeasonId || {}))
                    .map(seasonId => {
                      const season = orgSeasons?.find(b => b.id === seasonId);
                      const registrationPackage = season
                        ? orgRegistrationPackages?.find(
                            registrationPackage =>
                              item.settings?.registrationPackageIdBySeasonId![seasonId] === registrationPackage.id
                          )
                        : null;

                      if (season && !season.archivedAtMS && registrationPackage) {
                        return { season, registrationPackage };
                      } else {
                        return null;
                      }
                    })
                    .compact()
                    .map(a => {
                      const txt = shouldShowVerboseRegistrationPackages
                        ? `${a.registrationPackage.name} (${a.season.name})`
                        : a.registrationPackage.name;

                      return <Tag key={a.registrationPackage.id} text={txt} />;
                    })
                    .value()}
                </View>
              );
            }
          }
        ]}
        getRowHref={item => `${pathname}/${item.team.id}`}
        filters={[
          {
            filterComponent: (
              <CoolTextInput
                value={searchInput}
                onChange={newVal => {
                  setSearchInput(newVal ?? "");
                }}
                inputProps={{ placeholder: translate({ defaultMessage: "Search Name..." }) }}
              />
            ),
            onFilter(items) {
              return searchInput
                ? items.filter(item =>
                    item.team.name.toLowerCase().replaceAll(" ", "").includes(searchInput.toLowerCase().replaceAll(" ", ""))
                  )
                : items;
            }
          },
          {
            filterComponent: (
              <CoolSelectInput
                value={selectedBirthYear}
                onChange={newVal => {
                  setSelectedBirthYear(newVal ?? "");
                }}
                allowClear
                placeholder={translate.common.BirthYear}
                options={_.orderBy(birthYearOptions, a => a.label, "asc")}
              />
            ),
            onFilter(items) {
              return selectedBirthYear ? items.filter(item => item.team.birthYear === selectedBirthYear) : items;
            }
          },
          orgTeamTags?.length
            ? {
                filterComponent: (
                  <CoolSelectInput
                    options={_.orderBy(
                      orgTeamTags.map(orgTeamTag => {
                        return {
                          label: orgTeamTag.tag,
                          value: orgTeamTag.id
                        };
                      }),
                      a => a.label.toLowerCase,
                      "asc"
                    )}
                    value={selectedOrgTeamTagId ?? ""}
                    placeholder={translate.common.TeamTag}
                    allowClear
                    onChange={newVal => {
                      setSelectedOrgTeamTagId(newVal);
                    }}
                  />
                ),
                onFilter: items => {
                  return selectedOrgTeamTagId ? items.filter(item => item.team.assignedOrgTagIds?.[selectedOrgTeamTagId]) : items;
                }
              }
            : null,
          {
            filterComponent: (
              <CoolSelectInput
                options={_.orderBy(
                  orgRegistrationPackages?.map(orgRegistrationPackage => {
                    return {
                      label: orgRegistrationPackage.name,
                      value: orgRegistrationPackage.id
                    };
                  }),
                  a => a.label.toLowerCase(),
                  "asc"
                )}
                value={selectedOrgRegistrationPackageId ?? ""}
                placeholder={translate.common.RegistrationPackage}
                allowClear
                onChange={newVal => {
                  setSelectedOrgRegistrationPackageId(newVal);
                }}
              />
            ),
            onFilter: items => {
              return selectedOrgRegistrationPackageId
                ? items.filter(item =>
                    Object.values(item.settings?.registrationPackageIdBySeasonId || {}).includes(selectedOrgRegistrationPackageId)
                  )
                : items;
            }
          }
        ]}
      />
    </div>
  );
}

export function Tag(p: { text: string }) {
  return (
    <View
      style={{
        height: 24,
        alignItems: "center",
        marginBottom: 2,
        paddingLeft: 12,
        paddingRight: 12,
        borderRadius: 9999,
        marginRight: 6,
        backgroundColor: COLORS.blue,
        justifyContent: "center"
      }}
    >
      <Typography style={{ color: COLORS.white, fontSize: 12 }}>{p.text}</Typography>
    </View>
  );
}
