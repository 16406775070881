import PapaParse from "papaparse";
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { CSSProperties, MutableRefObject, ReactElement, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { CoolSelectInput } from "./Inputs/CoolSelectInput";
import { useMediaQuery, useTheme } from "@material-ui/core";
import _, { filter } from "lodash";
import { ImmutableStateSetter } from "../utils/useImmutableState";
import { useOnOutsideClick } from "../hooks/useOnOutsideClick";
import clsx from "clsx";
import { useImmutableSynchronousState, useSynchronousState } from "../hooks/useSynchronousState";
import { useKeyPress } from "../hooks/useKeyPress";
import { translate } from "@ollie-sports/i18n";
import { Link } from "react-router-dom";
import { CenteredLoader } from "./CenteredLoader";
import { dequal } from "dequal";
import { COLORS } from "@ollie-sports/core";
import { produce } from "immer";
import { ActionButtonDropdown } from "./ActionButtonDropdown";
import { StyledText } from "./StyledText";
import { More, MoreHoriz } from "@material-ui/icons";
import { InfoTooltip, InfoTooltipIcon } from "./InfoTooltip";
import { downloadStringAsFile } from "../utils/fileUtils";
import { extractTextFromReactNode } from "../utils/extractTextFromReactNode";

type SortSettings = { label: string; dir: "asc" | "desc" };

type EditableOpts<T> = {
  render: (p: {
    item: T;
    setItem: ImmutableStateSetter<T>;
    tempState: any;
    setTempState: (newTempState: any) => void;
  }) => ReactNode;
  getInitialTempStateOnEditStart?: (currItem: T) => any;
  onComplete: (updatedItem: T) => any | Promise<any>;
  shouldCompleteOnItemChange?: boolean;
  onError: (e: unknown) => void;
  isEditable?: (item: T) => boolean;
};

export type CoolerTableColumnDef<T> = {
  label: string;
  labelLegendValue?: string;
  infoToolTip?: string;
  getValue: (item: T) => string | ReactNode;
  toExportCsvString?: (item: T) => string;
  sortItems?: (items: T[], dir: "asc" | "desc") => T[];
  headerCustomClassName?: string;
  getCellCustomClassName?: (item: T) => string;
  renderHeaderTextWrapperComponent?: (text: string) => ReactNode;
  renderCellWrapper?: (item: T) => ReactNode;
  extraHeaderComponent?: ReactNode;
  isVisible?: boolean;
  getValueForTotalRow?: (items: T[]) => string;
  editable?: EditableOpts<T>;
  isBold?: boolean;
};

export type CoolerTableMethods = {
  downloadCurrentDataToCSV: (filename: string) => void;
};

export default function CoolerTable<T extends object>(p: {
  methodsRef?: MutableRefObject<CoolerTableMethods | null>;
  items: T[];
  paginationOptions?: { pageSizeOptions: number[]; defaultPageSize: number };
  condensed?: boolean;
  style?: CSSProperties;
  defaultCellClassName?: string;
  columnDefs: (CoolerTableColumnDef<T> | null | undefined)[];
  noItemsMessage?: string;
  noFilteredItemsMessage?: string;
  rowButtons?: {
    isVisible?: (item: T) => boolean;
    getLabel: (item: T) => string;
    getIcon?: (item: T) => JSX.Element | null;
    type: "inline" | "dropdown";
    headerLabel?: string;
    footerLabel?: string;
    onClick: (item: T) => Promise<void>;
    buttonClassName?: string;
    getTheme?: (item: T) => "red" | "blue";
  }[];
  filters?: (
    | {
        filterComponent: ReactNode;
        onFilter: (items: T[]) => T[];
        isVisible?: boolean;
        classname?: string;
      }
    | null
    | undefined
  )[];
  selectRowOptions?: {
    selectedItemsByKey: Record<string, true>;
    onUpdate: (items: Record<string, true>) => void;
    selectAllQuestionText?: (currData: T[]) => string;
  };
  enableHorizontalPadding?: boolean;
  defaultSortSettings?: SortSettings; // This only works if the label matches the label of a columnDef who also sortItems defined
  getItemKey: (item: T) => string;
  title?: string;
  isLoading?: boolean;
  subtitle?: string;
  getRowHref?: (item: T) => string | null; //Use this for navigation rather than onRowClick for accessibility
  onRowClick?: (item: T) => void;
  tableButtons?: {
    label: string;
    onClick: () => Promise<void>;
  }[];
  filterInstructions?: ReactElement;
  hideHeaders?: boolean;
  getRowCustomClassName?: (item: T) => string;
}) {
  const wasRowButtonJustClickedRef = useRef(false);
  const theme = useTheme();

  const isMediumDeviceOrSmaller = useMediaQuery(theme.breakpoints.down("md"));

  const condensed = p.condensed || isMediumDeviceOrSmaller;

  const columnDefNotHidden = _.compact(p.columnDefs).filter(colDef => colDef.isVisible !== false);
  const filtersNotHidden = _.compact(p.filters)?.filter(filter => filter.isVisible !== false);
  function getDefaultSortSettings(): SortSettings | undefined {
    if (p.defaultSortSettings) {
      return p.defaultSortSettings;
    }

    for (let i = 0; i < columnDefNotHidden.length; i++) {
      const columnDef = columnDefNotHidden[i];
      if (columnDef?.sortItems) {
        return {
          dir: "asc",
          label: columnDef?.label
        };
      }
    }
    return undefined;
  }
  const [sortSettings, setSortSettings] = useState<SortSettings | undefined>(getDefaultSortSettings());
  const [page, setPage] = useState<number | undefined>(p.paginationOptions ? 1 : undefined);
  const [pageSize, setPageSize] = useState<number | undefined>(p.paginationOptions?.defaultPageSize);
  const [lastItemKeySelected, setLastItemKeySelected] = useState<null | string>(null);

  const sortItemsFunction = sortSettings ? columnDefNotHidden.find(cd => cd.label === sortSettings.label)?.sortItems : undefined;

  const filteredItems = filtersNotHidden
    ? filtersNotHidden.reduce((acc, filter) => {
        return filter.onFilter(acc);
      }, p.items)
    : p.items;
  const sortedItems = sortItemsFunction && sortSettings ? sortItemsFunction(filteredItems, sortSettings.dir) : filteredItems;

  function onSortButtonClicked(label: string) {
    setSortSettings({
      label,
      dir: label === sortSettings?.label ? (sortSettings.dir === "asc" ? "desc" : "asc") : "asc"
    });
  }
  const itemsOnPage = pageSize && page ? sortedItems.slice((page - 1) * pageSize, page * pageSize) : sortedItems;

  const [inlineButtons, dropdownButtons] = _.partition(p.rowButtons || [], a => a.type === "inline");
  const shouldShowDropdownButtons = !!dropdownButtons?.filter(a => itemsOnPage.some(item => a.isVisible?.(item) ?? true)).length;

  const numRowButtonColumns = inlineButtons.length + Number(shouldShowDropdownButtons);

  const [uniqueTableClassName] = useState(() => "a" + Math.random().toString().slice(2) + Math.random().toString().slice(2));

  useLayoutEffect(() => {
    if (p.methodsRef) {
      const methods: CoolerTableMethods = {
        downloadCurrentDataToCSV: filename => {
          const data = sortedItems.map(item => {
            const mapped: Record<string, string | number> = {};
            for (const def of p.columnDefs) {
              if (!def?.label) {
                continue;
              }
              const val = def.toExportCsvString ? def.toExportCsvString(item) : extractTextFromReactNode(def?.getValue(item));
              if (typeof val !== "string" && typeof val !== "number") {
                continue;
              }
              mapped[def.label] = val;
            }
            return mapped;
          });
          const csvString = PapaParse.unparse(data, { header: true });
          downloadStringAsFile(filename, csvString);
        }
      };

      p.methodsRef.current = methods;

      return () => {
        p.methodsRef!.current = null;
      };
    }
  });

  return (
    <div style={p.style} className={`${uniqueTableClassName}${p.enableHorizontalPadding ? " px-4 sm:px-6 lg:px-8" : ""}`}>
      {p.title || p.subtitle || p.tableButtons?.length ? (
        <div className="flex items-center mb-4">
          {p.title || p.subtitle ? (
            <div className="flex-auto">
              {p.title ? <h1 className="text-xl font-semibold text-gray-900">{p.title}</h1> : null}
              {p.subtitle ? <p className="mt-2 text-sm text-gray-700">{p.subtitle}</p> : null}
            </div>
          ) : null}
          {p.tableButtons?.length ? (
            <div className="mt-4 sm:mt-0 sm:ml-16 flex-none">
              {p.tableButtons.map((tableButton, index) => {
                return (
                  <button
                    key={index}
                    onClick={tableButton.onClick}
                    type="button"
                    className={`items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-auto ${
                      index > 0 ? "ml-2" : ""
                    }`}
                  >
                    {tableButton.label}
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null}
      {p.filterInstructions || null}
      {filtersNotHidden.length ? (
        <div className={`mb-2 flex`}>
          {filtersNotHidden.map((filter, index) => {
            return (
              <div key={index} className={clsx(`mr-4 mb-2 sm:mb-0`, filter.classname)}>
                {filter.filterComponent}
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="flex flex-col">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="shadow">
            <div className="">
              <div className="table min-w-full">
                <div role="rowgroup" className="table-row-group bg-gray-50">
                  <div role="row" className="table-row">
                    {p.selectRowOptions && itemsOnPage ? (
                      <button
                        role="cell"
                        className={clsx("table-cell cursor-pointer px-2 py-1 sm:px-3 sm:py-4")}
                        style={{ fontSize: 0 }}
                        onClick={async e => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (Object.keys(p.selectRowOptions!.selectedItemsByKey).length >= 1) {
                            p.selectRowOptions!.onUpdate({});
                          } else {
                            if (itemsOnPage.length === filteredItems.length) {
                              //No need to confirm if it's a small table
                              p.selectRowOptions!.onUpdate(
                                itemsOnPage.reduce((acc, a) => {
                                  acc[p.getItemKey(a)] = true;
                                  return acc;
                                }, {} as Record<string, true>)
                              );
                            } else {
                              const yes = window.confirm(
                                p.selectRowOptions?.selectAllQuestionText?.(filteredItems) ??
                                  translate(
                                    {
                                      defaultMessage:
                                        "Select all {num} items? To select only the items on this page, select the first row, hold down the shift key, and then click the last row."
                                    },
                                    { num: filteredItems.length }
                                  )
                              );
                              if (yes) {
                                p.selectRowOptions!.onUpdate(
                                  filteredItems.reduce((acc, a) => {
                                    acc[p.getItemKey(a)] = true;
                                    return acc;
                                  }, {} as Record<string, true>)
                                );
                              }
                            }
                          }
                        }}
                      >
                        {!Object.keys(p.selectRowOptions!.selectedItemsByKey).length ||
                        Object.keys(p.selectRowOptions!.selectedItemsByKey).length === filteredItems.length ? (
                          <input
                            style={{ pointerEvents: "none", color: COLORS.blue }}
                            type="checkbox"
                            readOnly
                            checked={Object.keys(p.selectRowOptions!.selectedItemsByKey).length === filteredItems.length}
                          />
                        ) : (
                          <div style={{ backgroundColor: COLORS.blue }} className="h-4 w-4 flex items-center justify-center">
                            <div style={{ height: 2 }} className="bg-white w-2" />
                          </div>
                        )}
                      </button>
                    ) : null}
                    {columnDefNotHidden.map(cd => {
                      return p.hideHeaders ? null : (
                        <TableHeader
                          key={cd.label}
                          label={cd.label}
                          labelLegendValue={cd.labelLegendValue}
                          infoTooltip={cd.infoToolTip}
                          sortButtonOptions={
                            cd.sortItems && sortSettings
                              ? {
                                  onSortButtonClicked,
                                  sortSettings
                                }
                              : undefined
                          }
                          headerCustomClassName={cd.headerCustomClassName}
                          renderHeaderTextWrapperComponent={cd.renderHeaderTextWrapperComponent}
                          extraHeaderComponent={cd.extraHeaderComponent}
                          condensed={condensed}
                        />
                      );
                    })}
                    {new Array(numRowButtonColumns).fill(0).map((button, index) => {
                      return (
                        <div role="columnheader" key={index} className="table-cell py-3.5 pl-3 pr-4 sm:pr-6 z-100">
                          <span className="sr-only">{button.headerLabel}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {!p.isLoading ? (
                  <div role="rowgroup" className={clsx(["table-row-group bg-white"])}>
                    {itemsOnPage.map((item, index) => {
                      const rowCustomClassName = p.getRowCustomClassName?.(item);
                      const rowHref = p.getRowHref?.(item) ?? undefined;

                      const Elm = (rowHref ? Link : "div") as any;
                      const itemKey = p.getItemKey(item);

                      return (
                        <Elm
                          role="row"
                          to={rowHref ? rowHref : undefined}
                          onClick={
                            p.onRowClick
                              ? () => {
                                  if (!wasRowButtonJustClickedRef.current) {
                                    p.onRowClick?.(item);
                                  }
                                }
                              : undefined
                          }
                          className={clsx(
                            `table-row text-current no-underline border-b border-solid border-gray-200 sm:border-gray-100`,
                            (p.onRowClick || rowHref) && "cursor-pointer hover:bg-blue-50"
                          )}
                          key={itemKey}
                        >
                          {p.selectRowOptions ? (
                            <button
                              role="cell"
                              className={clsx(
                                p.getRowHref || p.onRowClick ? "cursor-auto" : "cursor-pointer",
                                "sm:table-cell px-2 py-1 sm:px-3 sm:py-4"
                              )}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                const thisItemNextState = !p.selectRowOptions!.selectedItemsByKey[itemKey];
                                let changedItemsByKey: Record<string, boolean> = {
                                  [itemKey]: thisItemNextState
                                };

                                if (e.shiftKey && lastItemKeySelected) {
                                  const lastIndex = itemsOnPage.findIndex(a => p.getItemKey(a) === lastItemKeySelected);
                                  if (lastIndex !== -1) {
                                    const thisIndex = itemsOnPage.findIndex(a => p.getItemKey(a) === itemKey);
                                    for (let i = Math.min(thisIndex, lastIndex); i <= Math.max(thisIndex, lastIndex); i++) {
                                      const curr = itemsOnPage[i];
                                      const key = p.getItemKey(curr);
                                      changedItemsByKey[key] = thisItemNextState;
                                    }
                                  }
                                }

                                const nextState = produce(p.selectRowOptions!.selectedItemsByKey, draft => {
                                  Object.keys(changedItemsByKey).forEach(key => {
                                    const nextVal = changedItemsByKey[key]!;
                                    if (!nextVal) {
                                      delete draft[key];
                                    } else {
                                      draft[key] = true;
                                    }
                                  });
                                });

                                setLastItemKeySelected(itemKey);
                                p.selectRowOptions!.onUpdate(nextState);
                              }}
                            >
                              <input
                                style={{ pointerEvents: "none", color: COLORS.blue }}
                                className={lastItemKeySelected === itemKey ? "outline outline-green-100" : ""}
                                type="checkbox"
                                readOnly
                                checked={!!p.selectRowOptions.selectedItemsByKey[itemKey]}
                              />
                            </button>
                          ) : null}
                          {columnDefNotHidden.map((cd, index) => {
                            return (
                              <TableCell
                                item={item}
                                key={index}
                                value={cd.getValue(item)}
                                editable={cd.editable}
                                cellCustomClassName={clsx(cd.getCellCustomClassName?.(item), p.defaultCellClassName)}
                                rowCustomClassName={rowCustomClassName}
                                condensed={condensed}
                                columnLabel={cd.label}
                                hideHeaders={p.hideHeaders}
                                uniqueTableClassName={uniqueTableClassName}
                              />
                            );
                          })}

                          {shouldShowDropdownButtons ? (
                            <div
                              role="cell"
                              className={`table-cell whitespace-nowrap pl-2 pr-2 py-1 sm:pl-3 sm:pr-4 sm:py-4 text-right text-sm font-medium sm:w-10 ${rowCustomClassName}`}
                            >
                              {dropdownButtons?.filter(a => a.isVisible?.(item) ?? true).length ? (
                                <ActionButtonDropdown
                                  color="default"
                                  style={{ backgroundColor: "white", border: "none", boxShadow: "none" }}
                                  actions={dropdownButtons
                                    .filter(a => a.isVisible?.(item) ?? true)
                                    .map((a, index) => ({
                                      key: index.toString(),
                                      label: () => a.getLabel(item) || "",
                                      onClick: () => a.onClick(item)
                                    }))}
                                >
                                  <MoreHoriz />
                                </ActionButtonDropdown>
                              ) : null}
                            </div>
                          ) : null}

                          {inlineButtons.map((button, buttonIndex) => {
                            if (button.isVisible?.(item) === false) {
                              return <div key={buttonIndex} role="cell" className="table-cell" />;
                            }

                            return (
                              <div
                                role="cell"
                                key={buttonIndex}
                                className={`table-cell whitespace-nowrap text-right text-sm font-medium sm:w-10 ${rowCustomClassName}`}
                                title={button.getLabel(item)}
                              >
                                <button
                                  onClick={async e => {
                                    if (wasRowButtonJustClickedRef.current) {
                                      return;
                                    }

                                    wasRowButtonJustClickedRef.current = true;
                                    e.preventDefault();
                                    await button.onClick?.(item);

                                    setTimeout(() => {
                                      wasRowButtonJustClickedRef.current = false;
                                    }, 1000);
                                  }}
                                  title={button.getLabel?.(item)}
                                  className={`flex flex-row pl-2 pr-2 py-1 sm:pl-3 sm:pr-4 sm:py-4 hover:opacity-50 ${
                                    button.getTheme?.(item) === "red"
                                      ? "text-red-600 hover:text-red-900"
                                      : "text-indigo-600 hover:text-indigo-900"
                                  } ${button.buttonClassName}`}
                                >
                                  {button.getIcon ? <div className={"h-5 w-5"}>{button.getIcon(item)}</div> : null}
                                </button>
                              </div>
                            );
                          })}
                        </Elm>
                      );
                    })}
                  </div>
                ) : null}

                {sortedItems.length && _.compact(p.columnDefs).find(cd => !!cd.getValueForTotalRow) ? (
                  <div role="rowgroup" className="table-footer-group bg-gray-50">
                    <div role="row" className="table-row">
                      {columnDefNotHidden.map(cd => {
                        return (
                          <TableHeader
                            key={cd.label}
                            infoTooltip={""}
                            label={cd.getValueForTotalRow ? cd.getValueForTotalRow(itemsOnPage) : ""}
                            headerCustomClassName={cd.headerCustomClassName}
                            renderHeaderTextWrapperComponent={cd.renderHeaderTextWrapperComponent}
                            extraHeaderComponent={cd.extraHeaderComponent}
                            condensed={condensed}
                          />
                        );
                      })}
                      {new Array(numRowButtonColumns).fill(0).map((button, index) => {
                        return (
                          <div key={index} role="cell" className="table-cell py-3.5 pl-3 pr-4 sm:pr-6 z-100">
                            <span className="sr-only">{button.footerLabel}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
              {p.isLoading ? (
                <div className="w-full text-center text-gray-500 italic p-6 bg-white">
                  <CenteredLoader />
                </div>
              ) : !itemsOnPage.length ? (
                <div
                  style={{ paddingRight: "10%", paddingLeft: "10%" }}
                  className=" text-center text-gray-500 italic p-6 bg-white"
                >
                  <span>
                    {p.items.length
                      ? p.noFilteredItemsMessage || translate({ defaultMessage: "No items match your filter criteria..." })
                      : p.noItemsMessage || translate({ defaultMessage: "No items created yet..." })}
                  </span>
                </div>
              ) : null}
              {page &&
              pageSize &&
              p.paginationOptions &&
              Math.min(...p.paginationOptions.pageSizeOptions) < sortedItems.length ? (
                <PageFooterControl
                  page={page}
                  totalNumberOfItems={sortedItems.length}
                  onSelectPage={newPage => {
                    setPage(newPage);
                  }}
                  pageSize={pageSize}
                  onSelectPageSize={newPageSize => {
                    setPageSize(newPageSize);
                    setPage(1);
                  }}
                  pageSizeOptions={p.paginationOptions.pageSizeOptions}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TableHeader(p: {
  label: string;
  sortButtonOptions?: {
    onSortButtonClicked: (label: string) => void;
    sortSettings: SortSettings;
  };
  infoTooltip?: string;
  labelLegendValue?: string;
  headerCustomClassName?: string;
  extraHeaderComponent?: ReactNode;
  renderHeaderTextWrapperComponent?: (text: string) => ReactNode;
  condensed?: boolean;
}) {
  const innerComponent = (
    <div className={`group inline-flex items-center ${p.condensed ? "text-xs" : ""} whitespace-normal sm:whitespace-nowrap`}>
      {p.renderHeaderTextWrapperComponent ? (
        p.renderHeaderTextWrapperComponent(p.label)
      ) : (
        <div className="flex flex-row items-center">
          {p.label}
          {p.labelLegendValue ? <div className="text-xs ml-0.5">{p.labelLegendValue}</div> : null}
          {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
        </div>
      )}

      <div className="ml-2">{p.extraHeaderComponent}</div>
    </div>
  );

  return (
    <div
      role="columnheader"
      className={clsx(
        "table-cell px-2 py-1 whitespace-nowrap sm:px-3 sm:py-4 text-left text-sm font-semibold text-gray-900",
        p.headerCustomClassName
      )}
    >
      {p.sortButtonOptions ? (
        <div
          onClick={e => {
            if (p.sortButtonOptions) {
              p.sortButtonOptions.onSortButtonClicked(p.label);
            }
          }}
          className="group inline-flex cursor-pointer"
        >
          {innerComponent}
          <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
            {p.sortButtonOptions.sortSettings.label === p.label && p.sortButtonOptions.sortSettings.dir === "desc" ? (
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </span>
        </div>
      ) : (
        innerComponent
      )}
    </div>
  );
}

function TableCell<T extends object>(p: {
  item: T;
  value: string | ReactNode;
  cellCustomClassName?: string;
  rowCustomClassName?: string;
  uniqueTableClassName: string;
  editable?: EditableOpts<T>;
  condensed?: boolean;
  columnLabel: string;
  hideHeaders?: boolean;
}) {
  const innerComponent = <div className={`group inline-flex items-center ${p.condensed ? "text-xs" : ""}`}>{p.value}</div>;

  const [editItemRef, setEditItemRaw] = useImmutableSynchronousState(p.item);

  const setEditItem: typeof setEditItemRaw = a => {
    const res = setEditItemRaw(a);

    if (p.editable?.shouldCompleteOnItemChange) {
      triggerComplete();
    }

    return res;
  };

  const lastClickTime = useRef<number>(0);
  const [isEditing, setIsEditing] = useSynchronousState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [minWidth, setMinWidth] = useState(0);

  const isEditable = !!p.editable && (p.editable.isEditable?.(p.item) ?? true);

  const innerRef = useRef<HTMLDivElement>(null);

  const triggerComplete = async () => {
    if (!dequal(editItemRef.current, p.item)) {
      try {
        await p.editable!.onComplete(editItemRef.current);
      } catch (e) {
        if (p.editable?.onError) {
          p.editable.onError(e);
        } else {
          console.error(e);
        }
      }
    }
  };

  const triggerDone = async (shouldTriggerOnComplete: boolean) => {
    unfreezeElements("." + p.uniqueTableClassName);
    setIsEditing(false);
    setIsFocused(false);
    ref.current?.blur();
    setMinWidth(0);

    if (shouldTriggerOnComplete) {
      await triggerComplete();
    }
  };

  const triggerEditor = () => {
    freezeElements("." + p.uniqueTableClassName);

    setTempState(p.editable?.getInitialTempStateOnEditStart?.(p.item));
    setMinWidth(innerRef.current!.clientWidth);
    setIsEditing(true);
    ref.current?.blur();
    setIsFocused(false);
    editItemRef.current = p.item;
    setTimeout(() => {
      const innerElm = (ref.current as HTMLElement).querySelector("input, textarea") as HTMLElement | null;
      innerElm?.focus?.();
      innerElm?.click?.();
    }, 0);
  };

  const [tempState, setTempState] = useSynchronousState<any>(null);

  const ref = useOnOutsideClick<HTMLTableCellElement>({
    callback: () => {
      triggerDone(true);
    },
    deps: [isEditing.current, isFocused],
    enabled: isEditing.current || isFocused
  });

  useKeyPress({
    deps: [],
    enabled: isFocused || isEditing.current,
    keys: ["Enter", "Escape"],
    callback: e => {
      if (isEditing.current && e.key === "Escape") {
        triggerDone(false);
      } else if (isEditing.current && e.key === "Enter") {
        triggerDone(true);
      } else if (isFocused && !isEditing.current && e.key === "Enter") {
        triggerEditor();
      }
    }
  });

  useEffect(() => {
    if (isEditing.current) {
      const listener = (event: FocusEvent) => {
        if (!ref.current?.contains(event.target as any)) {
          triggerDone(true);
        }
      };

      document.addEventListener("focus", listener, true);

      return () => {
        document.removeEventListener("focus", listener, true);
      };
    }
  }, [isEditing.current]);

  return (
    <div
      role="cell"
      ref={ref}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      tabIndex={isEditable ? 0 : undefined}
      onMouseDown={
        isEditable
          ? () => {
              if (isFocused && Date.now() - lastClickTime.current < 750 && innerRef?.current) {
                triggerEditor();
              } else if (!isEditing.current) {
                setIsFocused(true);
                ref.current?.focus();
                lastClickTime.current = Date.now();
              }
            }
          : undefined
      }
      className={clsx(
        `focus:outline focus:outline-blue-600 focus:-outline-offset-2 text-sm text-gray-500 table-cell align-middle`,
        isEditing.current ? null : "px-2 py-1 sm:px-3 sm:py-4",
        isEditable && !isEditing.current && "cursor-pointer hover:outline hover:outline-blue-100 hover:-outline-offset-2",
        p.rowCustomClassName,
        p.cellCustomClassName
      )}
    >
      {minWidth ? <div style={{ height: 1, width: minWidth }} /> : null}
      {!isEditing.current ? (
        <div ref={innerRef} className={clsx(["inline-block"])}>
          {innerComponent}
        </div>
      ) : null}
      {isEditing.current && p.editable
        ? p.editable.render({ item: editItemRef.current, setItem: setEditItem, tempState: tempState.current, setTempState })
        : null}
    </div>
  );
}

export function PageFooterControl(p: {
  page: number;
  totalNumberOfItems: number;
  onSelectPage: (newPage: number) => void;
  pageSize: number;
  onSelectPageSize: (pageSize: number) => void;
  pageSizeOptions: number[];
}) {
  const beginningNumber = p.totalNumberOfItems === 0 ? 0 : (p.page - 1) * p.pageSize + 1;
  const endingNumber = Math.min(p.totalNumberOfItems, p.page * p.pageSize);
  const numPages = Math.ceil(p.totalNumberOfItems / p.pageSize);

  let pageOptions: (number | "spacer")[] = [];
  if (numPages <= 10) {
    pageOptions = Array.from({ length: numPages }, (a, i) => i + 1);
  } else {
    if (p.page === 1) {
      pageOptions = [1, 2, "spacer", numPages - 1, numPages];
    } else if (p.page === 2) {
      pageOptions = [1, 2, 3, "spacer", numPages - 1, numPages];
    } else if (p.page === 3) {
      pageOptions = [1, 2, 3, 4, "spacer", numPages - 1, numPages];
    } else if (p.page === numPages) {
      pageOptions = [1, 2, "spacer", numPages - 1, numPages];
    } else if (p.page === numPages - 1) {
      pageOptions = [1, 2, "spacer", numPages - 2, numPages - 1, numPages];
    } else if (p.page === numPages - 2) {
      pageOptions = [1, 2, "spacer", numPages - 3, numPages - 2, numPages - 1, numPages];
    } else {
      pageOptions = [1, 2, "spacer", p.page - 1, p.page, p.page + 1, "spacer", numPages - 1, numPages];
    }
  }

  return (
    <div className="flex items-center justify-between border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {translate({ defaultMessage: "Previous" })}
        </a>
        <a
          href="#"
          className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {translate({ defaultMessage: "Next" })}
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{beginningNumber}</span> to <span className="font-medium">{endingNumber}</span>{" "}
            of <span className="font-medium">{p.totalNumberOfItems}</span> results
          </p>
        </div>
        <div>
          <div className="inline-flex items-center">
            <div className="text-sm text-gray-700 mr-2">Page size: </div>
            <CoolSelectInput
              inputProps={{
                className: "mr-4"
              }}
              options={p.pageSizeOptions.map(a => {
                return {
                  label: `${a}`,
                  value: `${a}`
                };
              })}
              value={`${p.pageSize}`}
              onChange={newVal => {
                const newPageSizeString = newVal;
                if (parseInt(newPageSizeString)) {
                  p.onSelectPageSize(parseInt(newPageSizeString));
                }
              }}
            />
            <p className="text-sm text-gray-700 mr-2 ml-2">Page: </p>
            <CoolSelectInput
              inputProps={{
                className: "mr-4"
              }}
              onChange={newVal => {
                const newPageString = newVal;
                if (parseInt(newPageString)) {
                  p.onSelectPage(parseInt(newPageString));
                }
              }}
              options={Array.from({ length: numPages }, (a, i) => i + 1).map(a => {
                return {
                  label: `${a}`,
                  value: `${a}`
                };
              })}
              value={`${p.page}`}
            />
            <nav className="ml-2 isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <a
                onClick={() => {
                  if (p.page > 1) {
                    p.onSelectPage(p.page - 1);
                  }
                }}
                href="#"
                className={`inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 ${
                  p.page > 1 ? "hover:bg-gray-50 focus:z-20" : "cursor-default"
                }`}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>

              {pageOptions.map(pageOption => {
                if (pageOption === "spacer") {
                  return <PageButton key={pageOption} type="spacer" />;
                }
                return (
                  <PageButton
                    key={pageOption}
                    type="page"
                    onClick={() => {
                      p.onSelectPage(pageOption);
                    }}
                    page={pageOption}
                    isSelected={pageOption === p.page}
                  />
                );
              })}

              <a
                onClick={() => {
                  if (p.page < numPages) {
                    p.onSelectPage(p.page + 1);
                  }
                }}
                href="#"
                className={`inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 ${
                  p.page < numPages ? "hover:bg-gray-50 focus:z-20" : "cursor-default"
                }`}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

function PageButton(p: { type: "spacer" } | { type: "page"; page: number; isSelected: boolean; onClick: () => void }) {
  return (
    <a
      onClick={p.type === "page" ? p.onClick : undefined}
      href="#"
      className={`inline-flex items-center border ${
        p.type === "page" && p.isSelected ? "border-indigo-500 bg-indigo-50 z-30 focus:z-20" : "border-gray-300 bg-white"
      } px-4 py-2 text-sm font-medium ${
        p.type === "page" && p.isSelected ? "text-indigo-600" : p.type === "page" ? "text-gray-500" : "text-gray-700"
      } ${p.type === "page" && !p.isSelected ? "hover:bg-gray-50 focus:z-20" : ""} ${
        p.type === "spacer" ? "cursor-default" : ""
      }`}
    >
      {p.type === "spacer" ? "..." : p.page}
    </a>
  );
}

function freezeElements(selector: string): void {
  const parentElements = document.querySelectorAll<HTMLElement>(selector);
  parentElements.forEach(parent => {
    const elements = parent.querySelectorAll<HTMLElement>("*");
    elements.forEach(element => {
      const computedStyle = getComputedStyle(element);
      const width = computedStyle.width;
      const height = computedStyle.height;

      element.style.width = width;
      element.style.height = height;
      element.dataset.frozenWidth = width;
      element.dataset.frozenHeight = height;
    });
  });
}

function unfreezeElements(selector: string): void {
  const parentElements = document.querySelectorAll<HTMLElement>(selector);
  parentElements.forEach(parent => {
    const elements = parent.querySelectorAll<HTMLElement>("*");
    elements.forEach(element => {
      if (element.dataset.frozenWidth && element.dataset.frozenHeight) {
        element.style.width = "";
        element.style.height = "";
        delete element.dataset.frozenWidth;
        delete element.dataset.frozenHeight;
      }
    });
  });
}
