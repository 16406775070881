import PapaParse from "papaparse";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import React, { MutableRefObject, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import stableStringify from "json-stable-stringify";
import _, { DebounceSettings, ThrottleSettings } from "lodash";
import { CoolSelectInput } from "./Inputs/CoolSelectInput";
import { StyledAsteriskText } from "./StyledAsteriskTest";
import { translate } from "@ollie-sports/i18n";
import { SetSearchParamsOptions, useSearchParamsState } from "../hooks/useSearchParamsState";
import { usePromise } from "../utils/hooks/usePromise";
import { useObjectVersion } from "../hooks/useObjectVersion";
import clsx from "clsx";
import { CenteredLoader } from "./CenteredLoader";
import { COLORS, ObjectKeys } from "@ollie-sports/core";
import { Link } from "react-router-dom";
import { SafeButton } from "./SafeButton";
import { usePersistentState } from "../utils/usePersistentState";
import { twMerge } from "tailwind-merge";
import { dequal } from "dequal";
import { produce } from "immer";
import { openLoadingIndicator } from "../utils/openLoadingIndicator";
import { openErrorToast } from "../utils/openErrorToast";
import { downloadStringAsFile } from "../utils/fileUtils";
import { wrapPromiseWithLoader } from "../utils/wrapPromiseWithLoader";
import { extractTextFromReactNode } from "../utils/extractTextFromReactNode";
import { StyleSheet } from "react-native-web";
import { ShadowView } from "./ShadowView";
import { InfoTooltip, InfoTooltipIcon } from "./InfoTooltip";

export type ColumnDef<T> = {
  getCell: (item: T) => ReactNode;
  toExportCsvString?: (item: T) => string;
  sortable?: boolean;
  getCellClassName?: (item: T) => string;
  label?: string;
  infoTooltip?: string;
  headerCellClassName?: string;
  renderExtraElementRightHeader?: () => ReactNode;
};

type FetchData<T> = { itemsToBeRendered: T[]; totalNumberOfItemsMatchingCriteria: number };

export type AsyncFancyTableMethods<T> = {
  downloadCurrentDataToCSV: (filename: string, opts?: { filter?: (t: T) => boolean }) => void;
  getCurrentNumRecords: () => number;
};

export type AsyncFancyTableProps<
  T extends any,
  ColKeys extends string,
  ColKeysSubset extends ColKeys,
  FilterValues extends {
    [str in string]: any;
  } = {}
> = {
  methodsRef?: MutableRefObject<AsyncFancyTableMethods<T> | null>;

  //Data Fetcher
  fetchItems: (criteria: {
    pagination: { page: number; numItemsPerPage: number };
    filters?: FilterValues;
    sort?: { key: ColKeys; dir: "asc" | "desc" }[];
  }) => Promise<FetchData<T>>;
  dataCachingKey: string;
  onIsFetchingUpdate?: (isFetching: boolean) => void;

  getRowKey: (item: T) => string;
  selectRowOptions?: {
    selectedItemsByKey: Record<string, true>;
    onUpdate: (items: Record<string, true>) => void;
    selectAllQuestionText?: (currData: FetchData<T>) => string;
  };

  noItemsMessage?: string;
  noFilteredItemsMessage?: string;

  //Columns
  columns: Record<ColKeys, ColumnDef<T> | null | undefined | false>;

  //Misc
  extraDeps?: (string | number | boolean)[]; //Change these when you want to trigger a refetch

  //Sort
  defaultSort?: { key: ColKeysSubset; dir: "asc" | "desc" }[]; //In order of precedence. Keys for columns without sortable=true will be ignored

  //Row Options
  getRowOptions?: (item: T) => {
    href?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  };

  //Buttons
  buttons?: {
    label?: (item: T) => string | JSX.Element;
    icon?: (item: T) => JSX.Element;
    onClick?: (item: T, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
    getHref?: (item: T) => string;
    buttonClassName?: (item: T) => string;
    isVisible?: (item: T) => boolean;
  }[];

  //Filters
  initialFilterValues?: FilterValues; //initial filter values must be set if you are using filters..
  renderFilters?: {
    [K in keyof FilterValues]: (a: {
      setValue: (newVal: FilterValues[K], opts?: SetSearchParamsOptions) => void;
      value: FilterValues[K];
      //It can be super handy to be able to set the state in a debounced/throttled manner, such as for a text input that you don't want to trigger a server request for every keystroke
      //Note: Debounce and throttle will NOT work if you change the options or ms between invocations. The options must be the same for the debounced/throttled effect to work.
      setValueDebounced: (newVal: FilterValues[K], ms: number, opts?: DebounceSettings & SetSearchParamsOptions) => void;
      setValueThrottled: (newVal: FilterValues[K], ms: number, opts?: ThrottleSettings & SetSearchParamsOptions) => void;
      valueImmediate: FilterValues[K]; //Usually equal to value unless setting value with debounce or throttle.
      allFilters: FilterValues;
      setAllFilters: (newFilters: FilterValues) => void;
      isTableFetching: boolean;
    }) => ReactNode | null | undefined | false;
  };
  renderFiltersWrapper?: (
    filterElms: ReactNode,
    currFilters: FilterValues,
    setFilters: (newVal: FilterValues) => void
  ) => ReactNode;

  //Pagination
  pagination: {
    pageSizes: number[];
    initialPageSize: number;
  };

  //Misc
  className?: string;
};

export function AsyncFancyTable<
  T extends any,
  ColKeys extends string,
  ColKeysSubset extends ColKeys,
  FilterValues extends {
    [str in string]: any;
  } = {}
>(p: AsyncFancyTableProps<T, ColKeys, ColKeysSubset, FilterValues>) {
  const [searchParams, setSearchParams] = useSearchParamsState<
    { page?: number; page_size?: number; sort: string } & Record<keyof FilterValues, string>
  >();

  const { page: pageQueryStr, page_size: pageSizeQueryStr, sort: sortQueryStr, ...filtersQuery } = searchParams;

  const page = (pageQueryStr ? parseInt(pageQueryStr) : "") || 1;
  const numItemsPerPage = (pageSizeQueryStr ? parseInt(pageSizeQueryStr) : "") || p.pagination?.initialPageSize;

  const sort =
    searchParams.sort?.split("-").map(a => {
      //separate keys by dash (-)
      const [key, dir] = a.split("."); //separate direction by an undescore (_)
      return { key, dir } as {
        key: ColKeys;
        dir: "asc" | "desc";
      };
    }) || p.defaultSort;

  const filters = { ...p.initialFilterValues, ...(filtersQuery as any) } as FilterValues;

  const fetchParams = { pagination: { numItemsPerPage, page }, filters, sort };
  const fetchParamsVersion = useObjectVersion(fetchParams);
  const fetchParamsVersionRef = useRef(fetchParamsVersion);
  fetchParamsVersionRef.current = fetchParamsVersion;

  const [lastItemKeySelected, setLastItemKeySelected] = useState<null | string>(null);

  const [cachedData, setCachedData] = usePersistentState({
    initialValue: null,
    key: p.dataCachingKey
  });

  const {
    data: fetchedData,
    error,
    forceRefresh,
    isFetching
  } = usePromise(() => {
    const thisVersion = fetchParamsVersion;
    return p.fetchItems(fetchParams).then(resp => {
      if (thisVersion !== fetchParamsVersionRef.current) {
        //Superseded fetch. Ignore
        return;
      }
      resp.totalNumberOfItemsMatchingCriteria = Number(resp.totalNumberOfItemsMatchingCriteria);
      if (typeof resp.totalNumberOfItemsMatchingCriteria !== "number" || isNaN(resp.totalNumberOfItemsMatchingCriteria)) {
        console.error("Unable to determine total number of items!");
        throw new Error("Unable to determine total number of items!");
      }
      setCachedData(resp as any);
      return resp;
    });
  }, [fetchParamsVersion, ...(p.extraDeps || [])]);

  useEffect(() => {
    p.onIsFetchingUpdate?.(isFetching);
  }, [isFetching]);

  const data = fetchedData || cachedData;

  useLayoutEffect(() => {
    if (p.methodsRef) {
      const methods: AsyncFancyTableMethods<T> = {
        getCurrentNumRecords: () => data?.totalNumberOfItemsMatchingCriteria || 0,
        downloadCurrentDataToCSV: async (filename, opts) => {
          const csvItemsPerPage = 500;
          const csvNumPages = Math.ceil((data?.totalNumberOfItemsMatchingCriteria || 0) / csvItemsPerPage);

          const pageNumbers = Array.from({ length: csvNumPages }, (_, i) => i);
          const allRecords = (
            await wrapPromiseWithLoader(
              Promise.all(
                pageNumbers.map(async index => {
                  const pageNumber = index + 1;
                  try {
                    const fetchParams = { pagination: { numItemsPerPage: csvItemsPerPage, page: pageNumber }, filters, sort };
                    const result = await p.fetchItems(fetchParams);
                    return result.itemsToBeRendered
                      .filter(item => {
                        if (opts?.filter) {
                          return opts.filter(item);
                        } else {
                          return true;
                        }
                      })
                      .map(item => {
                        const mapped: Record<string, string | number> = {};
                        for (const def of colDefs) {
                          const label = def.label || def.key;
                          const val = def.toExportCsvString
                            ? def.toExportCsvString(item)
                            : extractTextFromReactNode(def.getCell(item));
                          if (typeof val !== "string" && typeof val !== "number") {
                            continue;
                          }
                          mapped[label] = val;
                        }
                        return mapped;
                      });
                  } catch (error) {
                    console.error({ error });
                  }
                })
              ),
              { timeoutMS: 0 }
            )
          ).flat();

          const csvString = PapaParse.unparse(allRecords, { header: true });
          downloadStringAsFile(filename, csvString);
        }
      };

      p.methodsRef.current = methods;

      return () => {
        p.methodsRef!.current = null;
      };
    }
  });

  if (error) {
    return (
      <div className={clsx(p.className, "flex flex-col items-center justify-center my-5")}>
        <span className="text-red-600 mb-3">{translate({ defaultMessage: "Unable to fetch data. Please try again." })}</span>
        <button
          className="bg-blue-400 hover:bg-blue-600 text-white py-2 px-4 rounded"
          onClick={forceRefresh}
          disabled={isFetching}
        >
          {translate({ defaultMessage: "Retry" })}
        </button>
      </div>
    );
  }

  if (!data) {
    return (
      <div className={clsx(p.className, "flex items-center justify-center my-4")}>
        <CenteredLoader />
      </div>
    );
  }

  const numPages = Math.ceil(data.totalNumberOfItemsMatchingCriteria / numItemsPerPage);
  const pageOptions = getPageOptions({ numPages, page });

  const colDefs = _(ObjectKeys(p.columns))
    .filter(k => !!p.columns[k])
    .map(k => ({ ...p.columns[k], key: k }))
    .value() as any as (ColumnDef<any> & { key: string })[];

  return (
    <div className={p.className}>
      {/* FILTERS */}
      {p.renderFilters ? (
        <FiltersComponent
          filters={filters}
          setSearchParams={setSearchParams}
          selectRowOptions={p.selectRowOptions}
          renderFilters={p.renderFilters}
          renderFiltersWrapper={p.renderFiltersWrapper}
          isTableFetching={isFetching}
        />
      ) : null}

      <div role="table" className="table w-full shadow relative">
        {isFetching ? (
          <div style={{ ...StyleSheet.absoluteFillObject, justifyContent: "center", display: "flex", pointerEvents: "none" }}>
            <ShadowView style={{ position: "absolute", top: 40, borderRadius: 9999, padding: 8 }}>
              <CenteredLoader />
            </ShadowView>
          </div>
        ) : null}
        {/* TABLE HEADER */}
        <div role="rowgroup" className="table-header-group bg-gray-50">
          <div role="row" className="table-row">
            {p.selectRowOptions && data.itemsToBeRendered.length ? (
              <button
                role="cell"
                className={clsx("sm:table-cell cursor-pointer relative px-2 py-1 sm:px-3 sm:py-4")}
                style={{ fontSize: 0 }}
                onClick={async e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (Object.keys(p.selectRowOptions!.selectedItemsByKey).length >= 1) {
                    p.selectRowOptions!.onUpdate({});
                  } else {
                    if (data.itemsToBeRendered.length === data.totalNumberOfItemsMatchingCriteria) {
                      //No need to confirm if it's a small table
                      p.selectRowOptions!.onUpdate(
                        data.itemsToBeRendered.reduce((acc, a) => {
                          acc[p.getRowKey(a)] = true;
                          return acc;
                        }, {} as Record<string, true>)
                      );
                    } else {
                      const yes = window.confirm(
                        p.selectRowOptions?.selectAllQuestionText?.(data) ??
                          translate(
                            {
                              defaultMessage:
                                "Select all {num} items? To select only the items on this page, select the first row, hold down the shift key, and then click the last row."
                            },
                            { num: data.totalNumberOfItemsMatchingCriteria }
                          )
                      );
                      if (yes) {
                        const loader = openLoadingIndicator();
                        try {
                          const allItems = await p.fetchItems({
                            ...fetchParams,
                            pagination: { page: 1, numItemsPerPage: data.totalNumberOfItemsMatchingCriteria + 10 }
                          });

                          p.selectRowOptions!.onUpdate(
                            allItems.itemsToBeRendered.reduce((acc, a) => {
                              acc[p.getRowKey(a)] = true;
                              return acc;
                            }, {} as Record<string, true>)
                          );
                        } catch (e) {
                          openErrorToast(
                            translate({
                              defaultMessage:
                                "There was a problem selecting all items. Please try again or contact support@olliesports.com"
                            })
                          );
                        } finally {
                          loader.close();
                        }
                      }
                    }
                  }
                }}
              >
                {!Object.keys(p.selectRowOptions!.selectedItemsByKey).length ||
                Object.keys(p.selectRowOptions!.selectedItemsByKey).length === data.totalNumberOfItemsMatchingCriteria ? (
                  <input
                    style={{ pointerEvents: "none", color: COLORS.blue }}
                    type="checkbox"
                    readOnly
                    checked={
                      Object.keys(p.selectRowOptions!.selectedItemsByKey).length === data.totalNumberOfItemsMatchingCriteria
                    }
                  />
                ) : (
                  <div style={{ backgroundColor: COLORS.blue }} className="h-4 w-4 flex items-center justify-center">
                    <div style={{ height: 2 }} className="bg-white w-2" />
                  </div>
                )}
              </button>
            ) : null}
            {colDefs.map(colDef => {
              const Wrapper = colDef.sortable ? "button" : "div";
              const thisSortIndex = sort?.findIndex(a => a.key === colDef.key);
              const thisSort = sort?.[thisSortIndex ?? -1];

              return (
                <div key={colDef.key} role="cell" className={clsx("table-cell px-2 py-1 sm:px-3 sm:py-4")}>
                  <Wrapper
                    className="whitespace-nowrap flex text-left text-sm font-semibold text-gray-900 group"
                    onClick={
                      colDef.sortable
                        ? () => {
                            let newSort = sort?.slice() || [];
                            const currIndex = newSort.findIndex(a => a.key === colDef.key);
                            const currVal = newSort[currIndex];
                            const currDir = currVal?.dir || "desc";

                            if (currIndex === 0 && currDir === "asc") {
                              newSort.pop();
                            }
                            if (currIndex !== -1) {
                              newSort.splice(currIndex, 1);
                            }

                            newSort.unshift({ dir: currDir === "asc" ? "desc" : "asc", key: colDef.key as any });
                            newSort = newSort.slice(0, 3); //Max of three levels

                            setSearchParams({
                              sort: newSort.map(a => [a.key, a.dir].join(".")).join("-")
                            });
                          }
                        : undefined
                    }
                  >
                    <span>{colDef.label || ""}</span>
                    {colDef.infoTooltip ? <InfoTooltipIcon title={colDef.infoTooltip} /> : null}
                    {colDef.renderExtraElementRightHeader?.()}
                    {colDef.sortable ? (
                      thisSort?.dir === "asc" ? (
                        <ChevronUpIcon
                          className={twMerge("h-5 w-5 opacity-0 group-hover:opacity-100", thisSort && "opacity-100")}
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className={twMerge("h-5 w-5 opacity-0 group-hover:opacity-100", thisSort && "opacity-100")}
                          aria-hidden="true"
                        />
                      )
                    ) : null}
                  </Wrapper>
                </div>
              );
            })}
            {p.buttons?.map((__, i) => (
              <div key={i.toString()} className="table-cell" />
            ))}
          </div>
        </div>
        {/* Empty States */}
        {!data.itemsToBeRendered.length ? (
          <tr className={isFetching ? "opacity-0" : ""}>
            <td
              className="px-2 py-1 sm:px-3 sm:py-4 text-sm text-gray-500 text-center bg-white"
              colSpan={colDefs.length + (p.buttons?.length || 0) + Number(!!p.selectRowOptions)}
            >
              {dequal(filters, p.initialFilterValues)
                ? p.noItemsMessage || translate({ defaultMessage: "No items created yet..." })
                : p.noFilteredItemsMessage || translate({ defaultMessage: "No items found with selected filters..." })}
            </td>
          </tr>
        ) : null}

        {/* TABLE BODY */}
        {data.itemsToBeRendered.map(item => {
          const { href, onClick } = p.getRowOptions?.(item) || {};
          const RowElm = (href ? Link : "div") as any;
          const itemKey = p.getRowKey(item);
          return (
            <div key={itemKey} role="rowgroup" className="table-row-group bg-white">
              <RowElm
                role="row"
                className={clsx(
                  "table-row text-current no-underline border-b border-solid border-gray-200 sm:border-gray-100",
                  (onClick || href) && "cursor-pointer hover:bg-blue-50"
                )}
                to={href ? href : undefined}
                onClick={onClick}
              >
                {p.selectRowOptions ? (
                  <button
                    role="cell"
                    className={clsx(
                      onClick || href ? "cursor-auto" : "cursor-pointer",
                      "sm:table-cell relative px-2 py-1 sm:px-3 sm:py-4"
                    )}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();

                      const thisItemNextState = !p.selectRowOptions!.selectedItemsByKey[itemKey];
                      let changedItemsByKey: Record<string, boolean> = {
                        [itemKey]: thisItemNextState
                      };

                      if (e.shiftKey && lastItemKeySelected) {
                        const lastIndex = data.itemsToBeRendered.findIndex(a => p.getRowKey(a) === lastItemKeySelected);
                        if (lastIndex !== -1) {
                          const thisIndex = data.itemsToBeRendered.findIndex(a => p.getRowKey(a) === itemKey);
                          for (let i = Math.min(thisIndex, lastIndex); i <= Math.max(thisIndex, lastIndex); i++) {
                            const curr = data.itemsToBeRendered[i];
                            const key = p.getRowKey(curr);
                            changedItemsByKey[key] = thisItemNextState;
                          }
                        }
                      }

                      const nextState = produce(p.selectRowOptions!.selectedItemsByKey, draft => {
                        Object.keys(changedItemsByKey).forEach(key => {
                          const nextVal = changedItemsByKey[key]!;
                          if (!nextVal) {
                            delete draft[key];
                          } else {
                            draft[key] = true;
                          }
                        });
                      });

                      setLastItemKeySelected(itemKey);
                      p.selectRowOptions!.onUpdate(nextState);
                    }}
                  >
                    <input
                      style={{ pointerEvents: "none", color: COLORS.blue }}
                      className={lastItemKeySelected === itemKey ? "outline outline-green-100" : ""}
                      type="checkbox"
                      readOnly
                      checked={!!p.selectRowOptions.selectedItemsByKey[itemKey]}
                    />
                  </button>
                ) : null}
                {colDefs.map(colDef => {
                  return (
                    <div
                      key={colDef.key}
                      role="cell"
                      className={clsx(
                        "px-2 py-1 sm:px-3 sm:py-4 text-sm text-gray-500 flex flex-row sm:table-cell relative align-middle",
                        colDef.getCellClassName?.(item)
                      )}
                    >
                      {colDef.getCell(item)}
                    </div>
                  );
                })}
                {p.buttons?.map((button, buttonIndex) => {
                  let inner: ReactNode = null;

                  if (button.getHref) {
                    inner = (
                      <>
                        {button.label?.(item)}
                        {button.icon ? <div className={"h-5 w-5"}>{button.icon(item)}</div> : null}
                      </>
                    );
                  } else if (button.onClick) {
                    inner = (
                      <SafeButton
                        onClick={async e => {
                          e.preventDefault();
                          await button.onClick?.(item, e);
                        }}
                        className={clsx("flex flex-row", button.buttonClassName?.(item))}
                      >
                        {button.label?.(item)}
                        {button.icon ? <div className={"h-5 w-5"}>{button.icon(item)}</div> : null}
                      </SafeButton>
                    );
                  }

                  const Elm = button.getHref ? "a" : "div";

                  return (
                    <Elm
                      key={buttonIndex}
                      role="cell"
                      href={button.getHref?.(item)}
                      className={`table-cell relative whitespace-nowrap pl-2 pr-2 py-1 sm:pl-3 sm:pr-4 sm:py-4 text-right text-sm font-medium sm:w-10 align-middle hover:opacity-60 focus:opacity-60`}
                    >
                      {button.isVisible?.(item) ?? true ? inner : null}
                    </Elm>
                  );
                })}
              </RowElm>
            </div>
          );
        })}
      </div>

      {/* PAGINATION */}
      {p.pagination && Math.min(...p.pagination.pageSizes) < data.totalNumberOfItemsMatchingCriteria ? (
        <div className="flex items-center justify-between border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            <a
              href="#"
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {translate({ defaultMessage: "Previous" })}
            </a>
            <a
              href="#"
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {translate({ defaultMessage: "Next" })}
            </a>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                <StyledAsteriskText asteriskClassName="font-medium">
                  {translate(
                    {
                      defaultMessage: "Showing *{beginningNumber}* to *{endingNumber}* of *{totalNumberOfItems}* results"
                    },
                    {
                      beginningNumber: (page - 1) * numItemsPerPage + 1,
                      endingNumber: (page - 1) * numItemsPerPage + data.itemsToBeRendered.length,
                      totalNumberOfItems: data.totalNumberOfItemsMatchingCriteria
                    }
                  )}
                </StyledAsteriskText>
              </p>
            </div>
            <div>
              <div className="inline-flex items-center">
                <div className="text-sm text-gray-700 mr-2">Page size: </div>
                <CoolSelectInput
                  inputProps={{
                    className: "mr-4"
                  }}
                  options={p.pagination.pageSizes.map(a => {
                    return {
                      label: `${a}`,
                      value: `${a}`
                    };
                  })}
                  value={numItemsPerPage.toString()}
                  onChange={newVal => {
                    setSearchParams({ page_size: newVal });
                  }}
                />
                <p className="text-sm text-gray-700 mr-2 ml-2">Page: </p>
                <CoolSelectInput
                  inputProps={{
                    className: "mr-4"
                  }}
                  onChange={newVal => {
                    setSearchParams({ page: newVal });
                  }}
                  options={Array.from({ length: numPages }, (a, i) => i + 1).map(a => {
                    return {
                      label: `${a}`,
                      value: `${a}`
                    };
                  })}
                  value={page.toString()}
                />
                <nav className="ml-2 isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <a
                    onClick={() => {
                      if (page > 1) {
                        setSearchParams({ page: (page - 1).toString() });
                      }
                    }}
                    href="#"
                    className={clsx(
                      "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500",
                      page > 1 ? "hover:bg-gray-50 focus:z-20" : "cursor-default"
                    )}
                  >
                    <span className="sr-only">{translate({ defaultMessage: "Previous" })}</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </a>

                  {pageOptions.map(pageOption => {
                    if (pageOption === "spacer") {
                      return <PageButton key={pageOption} type="spacer" />;
                    }
                    return (
                      <PageButton
                        key={pageOption}
                        type="page"
                        onClick={() => {
                          setSearchParams({ page: pageOption.toString() });
                        }}
                        page={pageOption}
                        isSelected={pageOption === page}
                      />
                    );
                  })}

                  <a
                    onClick={() => {
                      if (page < numPages) {
                        setSearchParams({ page: (page + 1).toString() });
                      }
                    }}
                    href="#"
                    className={clsx(
                      "relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500",
                      page < numPages ? "hover:bg-gray-50 focus:z-20" : "cursor-default"
                    )}
                  >
                    <span className="sr-only">{translate.common.Next}</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function FiltersComponent(
  p: Pick<AsyncFancyTableProps<any, any, any, any>, "renderFiltersWrapper" | "renderFilters" | "selectRowOptions"> & {
    filters: Record<string, any>;
    setSearchParams: (newVal: any, opts?: SetSearchParamsOptions) => void;
    isTableFetching: boolean;
  }
) {
  const renderFiltersWrapper = p.renderFiltersWrapper ?? ((elm: ReactNode) => elm);
  const rateLimitedSetters = useRef<Record<string, ((newValue: any) => void) & _.Cancelable>>({});

  const [shadowFilterValues, setShadowFilterValues] = useState(p.filters);

  useEffect(() => {
    setShadowFilterValues(p.filters);
  }, [stableStringify(p.filters)]);

  return (
    <>
      {renderFiltersWrapper(
        ObjectKeys(p.renderFilters || {})
          .filter(Boolean)
          .map((k: any) => {
            const renderFilterFn = p.renderFilters![k]!;

            const setValue = (val: any, opts: any) => {
              p.selectRowOptions?.onUpdate({});
              Object.values(rateLimitedSetters.current).forEach(a => a.cancel());
              p.setSearchParams({ [k]: val } as any, opts);
              setShadowFilterValues(a => ({ ...a, [k]: val }));
            };

            return (
              <React.Fragment key={k}>
                {renderFilterFn({
                  setValue,
                  value: p.filters[k],
                  valueImmediate: shadowFilterValues[k],
                  setValueDebounced: (newValue, ms, opts) => {
                    const setterKey = stableStringify(["debounce", k, ms, opts]);

                    if (!rateLimitedSetters.current[setterKey]) {
                      (rateLimitedSetters.current as any)[setterKey] = _.debounce(
                        (newVal: any) => setValue(newVal, opts),
                        ms,
                        opts
                      );
                    }
                    setShadowFilterValues(a => ({ ...a, [k]: newValue }));
                    rateLimitedSetters.current[setterKey](newValue);
                  },
                  setValueThrottled: (newValue, ms, opts) => {
                    const setterKey = stableStringify(["throttle", k, ms, opts]);

                    if (!rateLimitedSetters.current[setterKey]) {
                      (rateLimitedSetters.current as any)[setterKey] = _.throttle(
                        (newVal: any) => setValue(newVal, opts),
                        ms,
                        opts
                      );
                    }

                    setShadowFilterValues(a => ({ ...a, [k]: newValue }));
                    rateLimitedSetters.current[setterKey](newValue);
                  },
                  allFilters: p.filters,
                  setAllFilters: newFiltersVal => {
                    p.selectRowOptions?.onUpdate({});
                    p.setSearchParams(newFiltersVal);
                    setShadowFilterValues(newFiltersVal);
                  },
                  isTableFetching: p.isTableFetching
                })}
              </React.Fragment>
            );
          }),
        shadowFilterValues,
        newFilters => {
          p.setSearchParams(_.mapValues(newFilters, a => a));
          setShadowFilterValues(newFilters);
        }
      )}
    </>
  );
}

function getPageOptions(p: { numPages: number; page: number }) {
  const numPages = p.numPages;
  let pageOptions: (number | "spacer")[] = [];
  if (numPages <= 10) {
    pageOptions = Array.from({ length: numPages }, (a, i) => i + 1);
  } else {
    if (p.page === 1) {
      pageOptions = [1, 2, "spacer", numPages - 1, numPages];
    } else if (p.page === 2) {
      pageOptions = [1, 2, 3, "spacer", numPages - 1, numPages];
    } else if (p.page === 3) {
      pageOptions = [1, 2, 3, 4, "spacer", numPages - 1, numPages];
    } else if (p.page === numPages) {
      pageOptions = [1, 2, "spacer", numPages - 1, numPages];
    } else if (p.page === numPages - 1) {
      pageOptions = [1, 2, "spacer", numPages - 2, numPages - 1, numPages];
    } else if (p.page === numPages - 2) {
      pageOptions = [1, 2, "spacer", numPages - 3, numPages - 2, numPages - 1, numPages];
    } else {
      pageOptions = [1, 2, "spacer", p.page - 1, p.page, p.page + 1, "spacer", numPages - 1, numPages];
    }
  }

  return pageOptions;
}

function PageButton(p: { type: "spacer" } | { type: "page"; page: number; isSelected: boolean; onClick: () => void }) {
  return (
    <a
      onClick={p.type === "page" ? p.onClick : undefined}
      href="#"
      className={`relative inline-flex items-center border ${
        p.type === "page" && p.isSelected ? "border-indigo-500 bg-indigo-50 z-30 focus:z-20" : "border-gray-300 bg-white"
      } px-4 py-2 text-sm font-medium ${
        p.type === "page" && p.isSelected ? "text-indigo-600" : p.type === "page" ? "text-gray-500" : "text-gray-700"
      } ${p.type === "page" && !p.isSelected ? "hover:bg-gray-50 focus:z-20" : ""} ${
        p.type === "spacer" ? "cursor-default" : ""
      }`}
    >
      {p.type === "spacer" ? "..." : p.page}
    </a>
  );
}
